
import { defineComponent, onMounted } from "vue";
import { platforms } from "@/data/providers/platform-provider";
import { View } from "@/views/constants";
import { state } from "@/data/providers/global-provider";
import { EditorModel } from "./editor-model";
import { code } from "./editor-state";
import Blockly from "@/components/Blockly/Blockly.vue";
import { EditorView, EditorButtons } from "./editor-type";
import { codemirror } from "vue-codemirror-lite";
import "codemirror/mode/python/python";
import "@/assets/codemirror.css";

export default defineComponent({
	name: View.Editor,
	components: {
		Blockly,
		codemirror
	},
	setup() {
		const view: EditorModel = new EditorModel();

		onMounted(() => {
			state.isSharedProject = true;
			if (state.platform?.name === "HTML" && !state.screenshotMode) {
				view.switchView(EditorView.Preview);
			}
			else {
				view.switchView(EditorView.Blocks);
			}

			if (state.platform?.name === "Python 3" && !state.screenshotMode) {
				view.runPythonCode();
			}
		});

		return { view, platforms, state, EditorView, code, EditorButtons };
	}
});
