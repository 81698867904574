import { Platform } from "@/data/providers/platform-provider";
import { EditorButtons } from "@/views/editor/editor-type";

export const circuitpython: Platform = {
	name: "CircuitPython",
	key: "CircuitPython",
	image: "/assets/images/platforms/circuitpython.png",
	icon: "/assets/images/platforms/circuitpython-icon.svg",
	color: "bg-gradient-to-b from-fuchsia-500 to-purple-600",
	actions: [EditorButtons.Save, EditorButtons.DownloadPython]
};