export enum View {
	Home = "Home",
	Editor = "Editor",
	Login = "Login",
	Projects = "Projects",
	Classroom = "Classroom",
	ClassroomDetail = "Classroom Detail",
	ClassroomAssignment = "Classroom Assignment",
	ClassroomJoin = "Classroom Join",
	Showcase = "Showcase",
	ShowcaseProject = "Showcase Project",
	ShowcaseProjects = "Showcase Projects",
	SharedProject = "Shared Project",
	Feedback = "Feedback",
	Guides = "Guides",
}