<template>
	<div class="flex flex-col w-full h-full">
		<EbPageHeader :title="view.getText('projects')">
			<div class="flex space-x-4">
				<EbButton
					v-if="authentication.currentUser.value && flagsmith.hasFeature('projects.import-local-file')"
					color="lightBlue"
					fa-icon="fas fa-file-import"
					:text="view.getText('import')"
					:tooltip="view.getText('import-tooltip')"
					@click="view.importFile()"
				/>
				<EbButton
					v-if="authentication.currentUser.value && flagsmith.hasFeature('projects.create-new-project')"
					color="blue"
					icon="PlusIcon"
					type="rounded"
					size="none"
					:tooltip="view.getText('create-tooltip')"
					@click="this.$router.push({path: '/editor'})"
				/>
				<EbButton
					v-if="!authentication.currentUser.value && flagsmith.hasFeature('projects.import-local-file')"
					color="blue"
					fa-icon="fas fa-file-import"
					:tooltip="view.getText('open-file')"
					:text="view.getText('open-file')"
					@click="view.importFile()"
				/>
			</div>
		</EbPageHeader>
		<div
			v-if="!view.state.isBusy && view.state.projects.length === 0 && authentication.currentUser.value"
			class="flex flex-col items-center justify-center w-full h-full mx-auto text-center"
		>
			<img
				src="/assets/images/general/empty-folder.svg"
				class="mb-6 h-44 justify-self-center"
			>
			<h1 class="mb-2 text-2xl font-medium text-gray-500">
				{{ view.getText('no-projects') }}
			</h1>
			<h1 class="text-gray-400 text-md">
				{{ view.getText('no-projects-description') }} 
				<span
					class="text-transparent cursor-pointer bg-gradient-to-r from-blue-300 to to-blue-600 bg-clip-text"
					@click="this.$router.push({path: '/editor'})"
				>
					{{ view.getText('here') }}
				</span>
			</h1>
		</div>
		<div
			v-if="!view.state.isBusy && !authentication.currentUser.value"
			class="flex flex-col items-center justify-center w-full h-full mx-auto text-center"
		>
			<img
				src="/assets/images/general/locked.svg"
				class="mb-6 h-44 justify-self-center"
			>
			<h1 class="mb-2 text-2xl font-medium text-gray-500">
				{{ view.getText('login') }}
			</h1>
			<h1 class="text-gray-400 text-md">
				{{ view.getText('login-description') }} 
				<span
					class="text-transparent cursor-pointer bg-gradient-to-r from-blue-300 to to-blue-600 bg-clip-text"
					@click="view.importFile()"
				>
					{{ view.getText('here') }}
				</span>
			</h1>
		</div>
		<div
			v-if="flagsmith.hasFeature('projects.projects-list') && authentication.currentUser.value"
			class="grid grid-cols-2 pb-8 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8"
		>
			<EbThumbnailCardSkeleton
				v-for="card in 8"
				:key="card"
				:visible="view.state.isBusy"
			/>
		
			<EbThumbnailCard
				v-for="project in view.state.projects"
				:key="project"
				:title="getLocalDataForFile(project).shortTitle" 
				:subtitle="getLocalDataForFile(project).platform?.name"
				:gradient-image="getLocalDataForFile(project).platform?.icon"
				:gradient="getLocalDataForFile(project).platform?.color"
				:dropdown-options="view.projectDropdown(project)"
				@cardClick="view.openProject(project)"
			/>
		</div>
		<EbModal
			:title="view.getText('assign-a-mode')"
			:visible="view.state.isImportModalOpen"
		>
			<template #content>
				<div class="space-y-4">
					<h1 class="text-sm">
						{{ view.getText('assign-mode') }}
					</h1>
					<EbSelect
						:options="view.getPlatformOptions()"
						@selected="state.platform = $event"
					/>
				</div>
			</template>
			<template #buttons>
				<EbButton
					:text="view.getText('open')"
					color="blue"
					@click="view.openImportedFile()"
				/>
			</template>
		</EbModal>

		<EbModal
			:title="view.getText('delete-error')"
			:visible="view.state.isDeleteErrorModelOpen"
		>
			<template #content>
				<h1 class="text-gray-900">
					{{ view.getText('delete-error-description') }}
				</h1>
			</template>
			<template #buttons>
				<EbButton
					:text="view.getText('close')"
					color="blue"
					@click="view.state.isDeleteErrorModelOpen = false"
				/>
			</template>
		</EbModal>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { ProjectsModel } from "./projects-model";
import { getLocalDataForFile } from "@/data/providers/files-provider";
import { state } from "@/data/providers/global-provider";
import { authentication } from "@/data/providers/authentication-provider";
import flagsmith from "flagsmith";

export default defineComponent({
	name: View.Projects,
	setup() {
		const view: ProjectsModel = new ProjectsModel();

		onMounted(() => {
			view.init();
		});

		return { view, getLocalDataForFile, state, authentication, flagsmith };
	}
});
</script>
