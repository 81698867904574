<template>
	<button
		v-if="visible"
		v-tippy="{ content: tooltip }"
		type="button"
		:class="component.variants({color, type, size})"
		class="space-x-2"
	>
		<h1
			v-if="text"
			class="mx-auto"
		>
			{{ text }}
		</h1>
		<component
			:is="icon"
			v-if="icon"
			class="w-5 h-5"
		/>
		<i
			v-if="faIcon"
			class="flex items-center justify-center w-5 h-5 text-sm fa-fw"
			:class="faIcon"
		/>
	</button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { EbButton } from "./eb-button";

export default defineComponent({
	name: ComponentName.EbButton,
	props: {
		text: {
			type: String
		},
		type: {
			type: String
		},
		color: {
			type: String
		},
		size: {
			type: String
		},
		tooltip: {
			type: String
		},
		icon: {
			type: String
		},
		faIcon: {
			type: String
		},
		visible: {
			type: Boolean,
			default: true
		}
	},
	setup() {
		const component: EbButton = new EbButton();

		return { component };
	}
});
</script>
