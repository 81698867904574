<template>
	<div class="space-y-8">
		<div class="overflow-hidden rounded-lg shadow-xl bg-gradient-to-b from-blue-300 to-blue-600 lg:grid lg:grid-cols-2 lg:gap-4">
			<div class="px-6 pt-10 pb-12 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
				<div class="lg:self-center">
					<h2 class="text-3xl font-extrabold text-white sm:text-4xl">
						<span class="block opacity-70">{{ view.getText("its-here") }}</span>
						<span class="block">{{ view.getText("welcome") }}</span>
					</h2>
					<p class="mt-4 text-lg leading-6 text-white opacity-90">
						{{ view.getText("welcome-description") }}
					</p>
					<div class="flex items-center mt-8 space-x-6">
						<a
							class="inline-flex items-center px-5 py-3 text-base font-medium text-blue-500 bg-white border border-transparent rounded-md shadow cursor-pointer hover:bg-indigo-50"
							@click="this.$router.push({path: '/editor'})"
						>
							{{ view.getText("get-started") }}
						</a>
						<div class="flex items-center space-x-1.5">
							<h1 class="text-white">
								{{ view.getText("sponsored-by") }}
							</h1>
							<img
								src="/assets/images/general/arm-logo.svg"
								class="h-3.5 mt-0.5"
							>
						</div>
					</div>
				</div>
			</div>
			<div class="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
				<img
					class="object-cover object-left-top transform translate-x-6 translate-y-6 rounded-md sm:translate-x-16 lg:translate-y-20"
					src="/assets/images/general/screenshot.png"
				>
			</div>
		</div>

		<div v-if="!authentication.currentUser.value || !view.state.isBusy && view.state.projects.length === 0">
			<EbPageHeader :title="view.getText('create-new-project')">
				<EbButton
					color="blue"
					:text="view.getText('new')"
					icon="PlusIcon"
					:tooltip="view.getText('create-a-project')"
					@click="this.$router.push({path: '/editor'})"
				/>
			</EbPageHeader>
			<div class="w-full pb-1 overflow-x-auto">
				<div class="grid w-full grid-flow-col gap-5 overflow-x-auto auto-cols-max">
					<EbThumbnailCard
						v-for="platform in platforms"
						:key="platform"
						:title="platform.name" 
						:subtitle="platform.description"
						:gradient-image="platform.icon"
						:gradient="platform.color"
						fixed
						@click="view.openEditorWithPlatform(platform)"
					/>
				</div>
			</div>
		</div>

		<!-- Saved Projects Section -->
		<div v-else>
			<EbPageHeader :title="view.getText('recent-projects')">
				<EbButton
					color="blue"
					:text="view.getText('new')"
					icon="PlusIcon"
					:tooltip="view.getText('create-a-project')"
					@click="this.$router.push({path: '/editor'})"
				/>
			</EbPageHeader>
			<div class="w-full pb-1 overflow-x-auto">
				<div class="grid w-full grid-flow-col gap-5 overflow-x-auto auto-cols-max">
					<EbThumbnailCardSkeleton
						v-for="card in 5"
						:key="card"
						:visible="view.state.isBusy"
						fixed
					/>
					<EbThumbnailCard
						v-for="project in view.state.projects.slice(0,5)"
						:key="project"
						:title="getLocalDataForFile(project).shortTitle" 
						:subtitle="getLocalDataForFile(project).platform?.name"
						:gradient-image="getLocalDataForFile(project).platform?.icon"
						:gradient="getLocalDataForFile(project).platform?.color"
						fixed
						@click="view.openProject(project)"
					/>
				</div>
			</div>
		</div>

		<!-- Guides Section -->
		<div class="pb-8">
			<EbPageHeader :title="view.getText('showcase')" />
			<div class="w-full pb-1">
				<splide
					v-if="!view.state.latestShowcaseProjectsBusy"
					:options="{autoWidth: true, pagination: false, gap: '1.25rem', trimSpace: 'move', padding: '0rem'}"
				>
					<splide-slide
						v-for="project in view.state.latestShowcaseProjects"
						:key="project"
					>
						<EbThumbnailCard
							:title="project.title"
							:subtitle="`${project.platform}`"
							fixed
							:image="project.image"
							@click="this.$router.push({path: `/showcase/${project.id}`})"
						/>
					</splide-slide>
				</splide>
				<div
					v-else
					class="grid w-full grid-flow-col gap-5 overflow-x-auto auto-cols-max"
				>
					<EbThumbnailCardSkeleton
						v-for="card in 5"
						:key="card"
						fixed
					/>
				</div>
			</div>
		</div>
	</div>
	<EbModal
		title="What is your name?"
		:visible="authentication.currentUser.value && !authentication.currentUser.value.displayName"
	>
		<template #content>
			<div class="space-y-4">
				<h1 class="text-sm">
					Before you use your EduBlocks account, we need to know your name.
				</h1>
				<EbInput
					v-model="view.state.userName"
					label="Name"
					background="white"
				/>
			</div>
		</template>
		<template #buttons>
			<EbButton
				text="Continue"
				color="blue"
				@click="view.state.userName ? authentication.updateName(view.state.userName) : null"
			/>
		</template>
	</EbModal>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { platforms } from "@/data/providers/platform-provider";
import { View } from "@/views/constants";
import { HomeModel } from "./home-model";
import { getLocalDataForFile } from "@/data/providers/files-provider";
import { formatDate } from "@/data/providers/global-provider";
import { authentication } from "@/data/providers/authentication-provider";

export default defineComponent({
	name: View.Home,
	setup() {
		const view: HomeModel = new HomeModel();

		onMounted(() => {
			view.getAllFilesFromFirebase();
			view.getLatestShowcaseProjects();
		});

		return { view, platforms, getLocalDataForFile, formatDate, authentication };
	}
});
</script>
