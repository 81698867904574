/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable brace-style */
/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { reactive } from "vue";
import { blocklyDiv, EditorState, code, xmlCode } from "./editor-state";
import { state } from "@/data/providers/global-provider";
import { getToolboxXml } from "@/platforms/blocks";
import { HTMLGenerator } from "@/platforms/html/html-generator";
import { EditorButton, EditorButtons, EditorTab, EditorView } from "./editor-type";
import { getTranslatedText } from "@/data/providers/localization-provider";
import pretty from "pretty";
import { saveFileToFirebaseAsync, saveLocalFile, savePythonFile } from "@/data/providers/files-provider";
import { authentication } from "@/data/providers/authentication-provider";
import router from "@/router";
import * as firebase from "firebase/app";
import { DropdownItem } from "@/components/eb-dropdown/eb-dropdown-types";
import { python } from "@/platforms/python";
import { microbit } from "@/platforms/microbit";
import { circuitpython } from "@/platforms/circuitpython";
import { raspberrypi } from "@/platforms/raspberrypi";
import { html } from "@/platforms/html";

import { useToast } from "vue-toastification";
import { initServerConnection, runCodeInTerminal } from "@/data/providers/server-provider";
import { all } from "@/platforms/general";
import { appInsights } from "@/main";

export class EditorModel {
	/**
	 * Get Translated text for the editor view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("editor", key);
	}

	// Editor view state
	public toast: any = useToast();
		
	// Editor view state
	public state: EditorState = reactive(new EditorState);

	/**
	 * Set global platform based on selection
	 */
	public setGlobalPlatform(): void {
		state.platform = this.state.selected;
		appInsights?.trackEvent({
			name: `Load ${state.platform?.name as string} Mode`
		});
		this.switchView(EditorView.Split);
		if (state.platform?.key === "RPi") {
			initServerConnection();
		}
	}

	/**
	 * Resize Blockly Window
	 */
	public resizeWindow(): void {
		window.dispatchEvent(new Event("resize"));
	}

	/**
	 * Check for mode in URL
	 */
	public checkForMode(): void {
		switch (router.currentRoute.value.query.mode?.toString().toLowerCase()) {
			case "python":
				state.platform = python;
				break;
			case "microbit":
				state.platform = microbit;
				break;
			case "html":
				state.platform = html;
				break;
			case "circuitpython":
				state.platform = circuitpython;
				break;
			case "rpi":
				state.platform = raspberrypi;
				break;
		}
		appInsights?.trackEvent({
			name: `Load ${state.platform?.name as string} Mode`
		});
	}

	/**
	 * Run Python Code
	 */
	public runPythonCode(): void {
		state.isSharedProject ? this.state.isBlockEditorActive = false : null;
		this.state.isTrinketWindowActive = true;
		this.state.isCodeWindowActive = false;
	}

	/**
	 * Run Python Code
	 */
	public stopPythonCode(): void {
		this.state.isTrinketWindowActive = false;
		state.isSharedProject ? null : this.state.isCodeWindowActive = true;
		state.isSharedProject ? this.state.isBlockEditorActive = true : null;
		if (state.isSharedProject) {
			this.switchView(EditorView.Blocks);
		}
	}

	/**
	 * Clear editor
	 */
	public clear(): void {
		this.state.isTrinketWindowActive = false;
		this.state.isCodeWindowActive = true;
		this.state.view = EditorView.Split;
		xmlCode.value = "";
		code.value = "";
		state.platform = undefined;
		state.filename = "";
	}

	public async getShareableURL(screenshot?: boolean): Promise<any> {
		const fileURL: string = await state.currentProject?.getDownloadURL();
		await state.currentProject?.updateMetadata({customMetadata: {"deleteDisabled" : "true"}}).then(async () => {
			console.log(await state.currentProject?.getMetadata());
		});
		const content: object = {
			dynamicLinkInfo: {
				domainUriPrefix: "https://project.edublocks.org",
				link: `https://staging.edublocks.org/#share${screenshot ? "?screenshot=true" : ""}?${state.platform?.key}?${btoa(fileURL)}`
			},
			suffix: {
				option: "SHORT" 
			}
		};
		return fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.VUE_APP_API_KEY}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(content)
		}).then((response: Response) => {
			return response.json();
		});
	}

	/**
	 * Switch Editor View
	 */
	public async switchView(view: EditorView): Promise<void> {
		this.state.view = view;
		switch (view) {
			case EditorView.Split:
				if (state.platform?.name === html.name) {
					this.state.isCodeWindowActive = false;
					this.state.isBlockEditorActive = true;
					this.state.isHTMLPreviewActive = true;
				}
				else {
					this.state.isCodeWindowActive = true;
					this.state.isBlockEditorActive = true;
				}
				break;
			case EditorView.Blocks:
				this.state.isCodeWindowActive = false;
				this.state.isBlockEditorActive = true;
				this.state.isHTMLPreviewActive = false;
				break;
			case EditorView.Code:
				this.state.isCodeWindowActive = true;
				this.state.isBlockEditorActive = false;
				this.state.isHTMLPreviewActive = false;
				break;
			case EditorView.Preview:
				this.state.isCodeWindowActive = false;
				this.state.isBlockEditorActive = false;
				this.state.isHTMLPreviewActive = true;
				break;
		}
		await window.dispatchEvent(new Event("resize"));
		await this.resizeWindow();
	}

	/**
	 * Define options for export menu
	 */
	public exportMenu: Array<DropdownItem> = [
		{ title: "Export Blocks", action: (): void => { saveLocalFile(); } },
		{ title: "Export Code", action: (): void => { savePythonFile(); } }
	]

	/**
	 * Define options for export menu
	 */
	public shareMenu: Array<DropdownItem> = [
		{ 
			title: this.getText("copy-link"), 
			description: this.getText("copy-link-description"), 
			faIcon: "fas fa-link", 
			action: (): void => { 
				this.getShareableURL().then((json: any) => {
					navigator.clipboard.writeText(json.shortLink).then(() => {
						this.toast.success(this.getText("copied")); 
					});
				});
			} 
		},
		{ 
			title: this.getText("copy-embed-code"), 
			description: this.getText("copy-embed-code-description"), 
			faIcon: "fas fa-code", 
			action: (): void => { 
				this.getShareableURL().then((json: any) => {
					navigator.clipboard.writeText(`<iframe src="${json.shortLink}" height="600px" width="900px"></iframe>`).then(() => {
						this.toast.success(this.getText("copied")); 
					});
				});
			}  
		},
		{ 
			title: this.getText("publish-to-showcase"), 
			description: this.getText("publish-to-showcase-description"), 
			faIcon: "fas fa-film", 
			action: (): void => { 
				this.getShareableURL(true).then(async (json: any) => {
					this.state.showcasePublishForm.title = state.filename as string;
					this.state.isShowcasePublishModalOpen = true;
					this.state.isThumbnailLoading = true;
					const fileURL: string = await state.currentProject?.getDownloadURL();
					this.state.showcasePublishForm.projectURL = `#share?${state.platform?.key}?${btoa(fileURL)}`;
					this.state.showcasePublishForm.cloneURL = `#clone?${state.platform?.key}?${btoa(fileURL)}`;
					this.state.showcasePublishForm.platform = state.platform?.name as string;
					this.state.showcasePublishForm.created = firebase.default.firestore.Timestamp.now();
					const response: Response = await fetch("https://festive-goldstine-1a618e.netlify.app/.netlify/functions/screenshot", {
						method: "POST",
						headers: {
							"Content-Type": "application/json"
						},
						body: JSON.stringify({
							url: json.shortLink
						})
					});
					this.state.showcasePublishForm.image = `data:image/png;base64,${await response.text()}`;
					this.state.showcasePublishForm.uid = authentication.currentUser.value?.uid as string;
					this.state.isThumbnailLoading = false;
				});
			 } 
		},
		{ 
			title: this.getText("share-to-google"), 
			description: this.getText("share-to-google-description"), 
			image: "/assets/images/general/google-classroom.svg", 
			action: (): void => { 
				this.getShareableURL().then((json: any) => {
					window.open(`https://classroom.google.com/u/0/share?url=${encodeURIComponent(json.shortLink)}&usegapi=1&id=I0_1591303124637&parent=https%3A%2F%2Fwww.gstatic.com&pfname=%2FI0_1591303123749&rpctoken=58755424&jsh=m%3B%2F_%2Fscs%2Fapps-static%2F_%2Fjs%2Fk%3Doz.gapi.en.utl9jrRztb8.O%2Fam%3DwQE%2Fd%3D1%2Fct%3Dzgms%2Frs%3DAGLTcCOUgIiKp6EMsn7UOgLQFm23i5pjzQ%2Fm%3D__features__`,"1591307119253","width=700,height=500,toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=600,top=300");
				});
			} 
		},
		{ 
			title: this.getText("share-to-microsoft"), 
			description: this.getText("share-to-microsoft-description"), 
			image: "/assets/images/general/microsoft-teams.svg", 
			action: (): void => { 
				this.getShareableURL().then((json: any) => {
					window.open(`https://teams.microsoft.com/share?href=${encodeURIComponent(json.shortLink)}`,"1591307119253","width=700,height=500,toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=600,top=300");
				});
			 } 
		}
	]

	public publishToShowcase(): void {
		authentication.db.collection("showcase").add(this.state.showcasePublishForm).then((doc: firebase.default.firestore.DocumentReference) => {
			router.push({path: `/showcase/${doc.id}`});
		});
	}

	/**
	 * Define list of tabs
	 */
	public editorTabs: Array<EditorTab> = [
		{ 
			title: this.getText("split"), 
			active: EditorView.Split, 
			tooltip: this.getText("switch-to-split"),
			action: (): void => {
				this.switchView(EditorView.Split); 
			}
		},
		{ 
			title: this.getText("blocks"), 
			active: EditorView.Blocks, 
			tooltip: this.getText("switch-to-blocks"),
			action: (): void => {
				this.switchView(EditorView.Blocks); 
			}
		},
		{ 
			title: this.getText("code"), 
			active: EditorView.Code, 
			tooltip: this.getText("switch-to-code"),
			action: (): void => {
				this.switchView(EditorView.Code); 
			}
		}
	]

	/**
	 * Get data for an Editor Button
	 */
	public getEditorButton(button: EditorButtons): EditorButton {
		let editorButton: EditorButton = { text: "", color: "", visible: true, action: (): void => { null; } };
		switch (button) {
			case EditorButtons.DownloadHex:
				editorButton = { 
					text: this.getText("download-hex"), 
					color: "blue", 
					icon: "LightningBoltIcon", 
					tooltip: this.getText("download-hex"),
					action: (): void => { this.downloadHex(); }
				};
				break;
			case EditorButtons.Save:
				editorButton = { 
					text: state.assignmentModeActive ? "Save Assignment" : this.getText("save"), 
					color: "lightBlue", 
					tooltip: "Save Project",
					action: (): void => { state.assignmentModeActive ? this.saveToAssignment().then(() => {this.state.isSaved = true; setTimeout(() => {this.state.isSaved = false;}, 2000);}) : saveFileToFirebaseAsync().then(() => {this.state.isSaved = true; setTimeout(() => {this.state.isSaved = false;}, 2000);}); }
				};
				break;
			case EditorButtons.DownloadPython:
				editorButton = { 
					text: this.getText("download-python"), 
					color: "blue", 
					faIcon: "fas fa-cloud-download-alt",
					tooltip: this.getText("download-python"),
					action: (): void => { savePythonFile(); }
				};
				break;
			case EditorButtons.Run:
				editorButton = { 
					text: this.state.isTrinketWindowActive ? this.getText("stop") : this.getText("run"), 
					color: this.state.isTrinketWindowActive ? "red" : "blue", 
					faIcon: this.state.isTrinketWindowActive ? "fas fa-stop" : "fas fa-play", 
					tooltip: this.state.isTrinketWindowActive ? this.getText("stop") : this.getText("run"), 
					action: (): void => { this.state.isTrinketWindowActive ? this.stopPythonCode() : this.runPythonCode(); }
				};
				break;
			case EditorButtons.RunTerminalCode:
				editorButton = { 
					text: this.state.isRemoteTerminalWindowActive ? this.getText("stop") : this.getText("run"), 
					color: this.state.isRemoteTerminalWindowActive ? "red" : "blue", 
					faIcon: this.state.isRemoteTerminalWindowActive ? "fas fa-stop" : "fas fa-play", 
					tooltip: this.state.isRemoteTerminalWindowActive ? this.getText("stop") : this.getText("run"), 
					action: (): void => { 
						if (this.state.isRemoteTerminalWindowActive) {
							this.state.isRemoteTerminalWindowActive = false;
							this.state.isCodeWindowActive = true;
						}
						else {
							this.state.isCodeWindowActive = false;
							this.state.isRemoteTerminalWindowActive = true;
							runCodeInTerminal();
						}
					 }

				};
				break;
			case EditorButtons.Popout:
				editorButton = { 
					text: this.getText("popout-preview"), 
					color: "blue", 
					icon: "ExternalLinkIcon", 
					action: (): void => { 
						const win: Window | null = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=500");
						if (win) {
							win.document.body.innerHTML = code.value;
						}
					}
				};
				break;
		}
		return editorButton;
	}

	/**
	 * Set Blockly XML Code
	 */
	public setXml(xml?: string | null): void {
		if (!Blockly.mainWorkspace) {
		  throw new Error("No Workspace");
		}
	
		Blockly.mainWorkspace.clear();
	
		let start: number | undefined = 0;
		let newXml: string ="<xml xmlns=\"https://developers.google.com/blockly/xml\"><block type=\"events_start_here\" id=\"DI_start_here\" x=\"" + 25 +"\" y=\"33\" deletable=\"false\" movable=\"false\"></block></xml>";
		if (state.platform?.name !== html.name) {
			if (typeof xml === "string") {
		  	start = xml.search("DI_start_here");
				if (start < 0) {
					const firstBlockPosition: number = xml.search("<block");
					const startBlockXml: string = "<block type=\"events_start_here\" id=\"DI_start_here\" x=\"" +25 +"\" y=\"33\" deletable=\"false\" movable=\"false\">";
	
					if (firstBlockPosition < 0) {
						// No Blocks
					}
					else {
						const posFromEndOfString: number = -1 * "</xml>".length;
						newXml = xml.slice(0, firstBlockPosition) + startBlockXml + "<next>" + xml.slice(firstBlockPosition, posFromEndOfString) + "</next></block>" + xml.slice(posFromEndOfString);
					}
					const textToDom: Element = Blockly.Xml.textToDom(newXml);
					Blockly.Xml.domToWorkspace(textToDom, Blockly.mainWorkspace);
				}
				else {
					const textToDom: Element = Blockly.Xml.textToDom(xml);
					Blockly.Xml.domToWorkspace(textToDom, Blockly.mainWorkspace);
				}
			}
		}
		else {
			if (typeof xml === "string" && xml) {
				const textToDom: Element = Blockly.Xml.textToDom(xml);
				Blockly.Xml.domToWorkspace(textToDom, Blockly.mainWorkspace);
			}
		}
	}
	
	/**
	 * Loading blockly editor
	 */
	public async loadBlockly(): Promise<void> {
		// window.onbeforeunload = function(): boolean {
		// 	return true;
		// };
		const toolbox: string = await getToolboxXml();
	
		if (Blockly.mainWorkspace) {
		  Blockly.mainWorkspace.dispose();
		}
	
		const options: object = {
		  media: "blockly/media/",
		  renderer: "pxt",
		  trashcan: false,
		  scrollbars: true,
		  readOnly: state.isSharedProject ? true : false,
		  zoom: {
				controls: true,
				wheel: true,
				startScale: 1.0,
				maxScale: 3,
				minScale: 0.3,
				scaleSpeed: 1.2
		  },
		  pinch: true,
		  grid: {
				spacing: 25,
				length: 3,
				colour: "#ccc",
				snap: true
		  },
		  toolbox: state.isSharedProject ? null : toolbox
		};
	
		const blocklyWorkspace: Blockly.WorkspaceSvg = Blockly.inject(
		  blocklyDiv.value,
		  options
		);

		if (state.platform?.name !== html.name || state.platform?.name !== all.name) {
			blocklyWorkspace.addChangeListener(Blockly.Events.disableOrphans);
		}
				
		blocklyWorkspace.addChangeListener(() => {
			xmlCode.value = Blockly.Xml.domToPrettyText(
				Blockly.Xml.workspaceToDom(blocklyWorkspace)
		  );
		  if (!blocklyWorkspace.isDragging()) {
			  	this.state.isSaved = false;
				if (state.platform?.name === html.name || state.platform?.name === all.name) {
					code.value = pretty(HTMLGenerator.workspaceToCode(blocklyWorkspace));
				}
				else {
					code.value = Blockly.Python.workspaceToCode(blocklyWorkspace);
				}
		  }
		});
	
		this.setXml(xmlCode.value);
	  }

	  public async saveToAssignment(): Promise<void> {
		await authentication.db.collection("classrooms").doc(router.currentRoute.value.query.classroomID?.toString()).collection("assignments").doc(router.currentRoute.value.query.assignmentID?.toString()).collection("submissions").where("IDs", "==", {assignmentID: router.currentRoute.value.query.assignmentID?.toString(), uid: authentication.currentUser.value?.uid}).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			if (snapshot.docs.length > 0) {
				snapshot.forEach((doc: firebase.default.firestore.QueryDocumentSnapshot) => {
					if (doc.data()) {
						doc.ref.update({
							xmlCode: xmlCode.value
						});
					}
				});
			}
			else {
				this.createAssignmentSubmission();
			}
		});
	}

	public async createAssignmentSubmission(): Promise<void> {
		await authentication.db.collection("classrooms").doc(router.currentRoute.value.query.classroomID?.toString()).collection("assignments").doc(router.currentRoute.value.query.assignmentID?.toString()).collection("submissions").add({
			IDs: { uid: authentication.currentUser.value?.uid, assignmentID: router.currentRoute.value.query.assignmentID?.toString() },
			xmlCode: xmlCode.value,
			submitted: false,
			markedByTeacher: false,
			marks: 0,
			grade: "",
			studentComments: "",
			teacherComments: "",
		}).then((doc: firebase.default.firestore.DocumentReference) => {
			authentication.db.collection("classrooms").doc(router.currentRoute.value.query.classroomID?.toString()).collection("assignments").doc(router.currentRoute.value.query.assignmentID?.toString()).update({
				submissions: firebase.default.firestore.FieldValue.arrayUnion(doc.id)
			});
		});
	}

	public downloadHex(): void {
		let filename: string = "";
		if (state.filename) {
			filename = state.filename;
		}
		else {
			filename = "untitled";
		}
		fsUniversalHex(code.value, filename);
	}
}