import { Platform } from "@/data/providers/platform-provider";
import { EditorButtons } from "@/views/editor/editor-type";

export const html: Platform = {
	name: "HTML",
	key: "HTML",
	image: "/assets/images/platforms/html.png",
	icon: "/assets/images/platforms/html-icon.svg",
	color: "bg-gradient-to-b from-amber-300 to-orange-500",
	actions: [EditorButtons.Save, EditorButtons.Popout]
};