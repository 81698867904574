<template>
	<div class="relative flex flex-col justify-center h-full">
		<div
			style="background-image: url('/assets/images/general/topography.svg')"
			class="absolute z-10 w-full h-full bg-cover opacity-[0.017]"
		/>
		<div class="z-50 sm:mx-auto sm:w-full sm:max-w-md">
			<h2 class="text-3xl font-bold text-center text-gray-900 dark:text-white">
				{{ view.getText('sign-in-register') }}
			</h2>
		</div>

		<div class="z-50 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
			<div class="px-4 py-8 bg-white rounded-lg shadow-lg sm:px-10 dark:bg-gray-800">
				<form class="flex flex-col space-y-6">
					<div>
						<EbInput
							id="email"
							v-model="view.state.email"
							name="email"
							type="email"
							:label="view.getText('email-address')"
							autocomplete="email"
							required
							background="white"
						/>
					</div>

					<div>
						<EbInput
							id="password"
							v-model="view.state.password"
							name="password"
							type="password"
							:label="view.getText('password')"
							autocomplete="password"
							required
							background="white"
						/>
					</div>

					<div class="flex space-x-4">
						<EbButton
							:text="view.getText('sign-in')"
							color="blue"
							class="w-1/2 text-center"
							size="lg"
							@click="view.login()"
						/>
						<EbButton
							:text="view.getText('register')"
							color="lightBlue"
							class="w-1/2"
							size="lg"
							@click="view.register()"
						/>
					</div>
				</form>
				<div class="mt-6">
					<div class="relative">
						<div class="absolute inset-0 flex items-center">
							<div class="w-full border-t border-gray-300 dark:border-gray-900" />
						</div>
						<div class="relative flex justify-center text-sm">
							<span class="px-2 text-gray-500 bg-white dark:bg-gray-800 dark:text-white">
								{{ view.getText('or-continue-with') }}
							</span>
						</div>
					</div>

					<div class="grid grid-cols-3 gap-3 mt-6">
						<div>
							<a
								class="inline-flex justify-center w-full h-10 py-1 text-sm font-medium text-gray-500 transition-all bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-900"
								@click="view.socialLogin(FirebaseProviders.Google)"
							>
								<i class="h-10 text-lg fab fa-google" />
							</a>
						</div>
						<div>
							<a
								class="inline-flex justify-center w-full h-10 py-1 text-sm font-medium text-gray-500 transition-all bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-900"
								@click="view.socialLogin(FirebaseProviders.Microsoft)"
							>
								<i class="h-10 text-lg fab fa-microsoft" />
							</a>
						</div>
						<div>
							<a
								class="inline-flex justify-center w-full h-10 py-1 text-sm font-medium text-gray-500 transition-all bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-900"
								@click="view.socialLogin(FirebaseProviders.Apple)"
							>
								<i class="h-10 text-lg fab fa-apple" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { platforms } from "@/data/providers/platform-provider";
import { View } from "@/views/constants";
import { LoginModel } from "./login-model";
import { FirebaseProviders } from "@/data/providers/authentication-provider";

export default defineComponent({
	name: View.Login,
	setup() {
		const view: LoginModel = new LoginModel();

		return { view, platforms, FirebaseProviders };
	}
});
</script>
