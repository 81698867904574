import { authentication } from "@/data/providers/authentication-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";
import router from "@/router";
import { reactive } from "@vue/reactivity";
import { ShowcaseProjectsState } from "./showcase-projects-state";
import { ShowcaseProject } from "./showcase-projects-types";

export class ShowcaseProjectsModel {
	// State for Showcase View
	public state: ShowcaseProjectsState = reactive(new ShowcaseProjectsState());
		
	/**
	 * Get Translated text for the showcase view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("showcase", key);
	}

	public init(): void {
		this.getPlatform();
	}

	public getPlatform(): void {
		switch (router.currentRoute.value.params.mode.toString().toLowerCase()) {
			case "all":
				this.state.title = "All Projects";
				this.state.platform = "all";
				break;
			case "python":
				this.state.title = "Python Projects";
				this.getProjects("Python 3");
				this.state.platform = "Python 3";
				break;
			case "html":
				this.state.title = "HTML Projects";
				this.getProjects("HTML");
				this.state.platform = "HTML";
				break;
			case "microbit":
				this.state.title = "micro:bit Projects";
				this.getProjects("micro:bit");
				this.state.platform = "micro:bit";
				break;
			case "circuitpython":
				this.state.title = "CircuitPython Projects";
				this.getProjects("CircuitPython");
				this.state.platform = "CircuitPython";
				break;
		}
	}

	public getProjects(platform: string): void {
		this.state.projectsBusy = true;
		authentication.db.collection("showcase").where("platform", "==", platform).orderBy("created", "desc").get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			snapshot.docs.forEach((snapshot: firebase.default.firestore.QueryDocumentSnapshot) => {
				if (snapshot.data()) {
					const project: ShowcaseProject = snapshot.data() as ShowcaseProject;
					project.id = snapshot.id;
					this.state.projects.push(project);
				}
				this.state.projectsBusy = false;
			});
		}).catch((error: Error) => {
			console.log(error);
		});
	}

	public getProjectsWithSearchTerm(): void {
		this.state.projectsBusy = true;
		authentication.db.collection("showcase").where("platform", "==", this.state.platform).where("title", ">=", this.state.search).where("title", "<=", `${this.state.search}\uf8ff`).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			this.state.projects = [];
			snapshot.docs.forEach((snapshot: firebase.default.firestore.QueryDocumentSnapshot) => {
				if (snapshot.data()) {
					const project: ShowcaseProject = snapshot.data() as ShowcaseProject;
					project.id = snapshot.id;
					this.state.projects.push(project);
				}
				this.state.projectsBusy = false;
			});
		}).catch((error: Error) => {
			console.log(error);
		});
	}
	
}