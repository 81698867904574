<template>
	<!-- Platform Selector -->
	<div
		v-if="!state.platform"
		class="flex flex-col w-screen h-screen"
	>
		<div class="flex flex-none w-full h-16 bg-white border-b border-gray-200">
			<div
				class="flex items-center justify-center h-full px-6 space-x-3 text-gray-500 transition-all border-r border-gray-200 cursor-pointer hover:text-blue-500"
				@click="this.$router.push({path: '/'})"
			>
				<i class="fas fa-chevron-left" />
				<span>{{ view.getText('back') }}</span>
			</div>
		</div>
		<div class="flex items-center justify-center w-full h-full px-6">
			<div class="w-full space-y-8 max-w-[90rem]">
				<div>
					<h1 class="text-5xl font-extrabold text-blue-500 sm:text-center">
						{{ view.getText('get-started') }}
					</h1>
					<p class="mt-5 text-xl text-gray-500 sm:text-center dark:text-gray-400">
						{{ view.getText('choose-a-platform') }}
					</p>
				</div>
				<EbRadioGroup
					v-model="view.state.selected"
					:options="platforms"
					name="platforms"
				/>
			</div>
		</div>
		<div class="flex items-center flex-none w-full h-20 px-6 space-x-6 bg-white border-t">
			<EbInput
				v-model="state.filename"
				:label="view.getText('filename')"
				background="white"
				class="ml-auto w-80"
			/>
			<EbButton
				v-if="view.state.selected"
				:text="view.getText('start-coding')"
				color="blue"
				@click="view.setGlobalPlatform()"
			/>
		</div>
	</div>

	<!-- Editor -->
	<div
		v-else
		class="flex flex-col w-full h-full"
	>
		<EbToolbar>
			<template #buttonsLeft>
				<EbDropdown
					:options="view.exportMenu"
					type="small"
				>
					<i
						v-tippy="{ content: 'Export Project' }"
						class="ml-3 text-gray-400 transition-all cursor-pointer fas fa-cloud-download-alt hover:text-gray-500"
					/>
				</EbDropdown>
			</template>
			<template #buttonsRight>
				<div class="flex items-center px-6 text-sm">
					<span
						v-if="view.state.isSaved"
						class="text-green-400"
					>
						{{ view.getText('saved') }}
					</span>
					<span
						v-else
						class="text-gray-400"
					>
						{{ state.platform.name }}
					</span>
				</div>
				<div class="flex px-6 space-x-6">
					<EbButton
						color="outline"
						type="rounded"
						size="none"
						fa-icon="fas fa-book"
						class="flex-none"
						:tooltip="view.getText('sample-projects')"
						@click="this.$router.push({path: '/showcase'})"
					/>
					<EbDropdown
						v-if="authentication.currentUser.value && state.isFirebaseProject"
						:options="view.shareMenu"
						type="large"
						class="z-[40]"
					>
						<EbButton
							color="outline"
							type="rounded"
							size="none"
							class="flex-shrink-0"
							fa-icon="fas fa-share-alt"
							:tooltip="view.getText('share-project')"
						/>
					</EbDropdown>
				</div>
				<div class="px-6 space-x-6">
					<EbButton
						v-for="button in state.platform.actions"
						:key="button"
						:text="view.getEditorButton(button).text"
						:color="view.getEditorButton(button).color"
						:icon="view.getEditorButton(button).icon"
						:fa-icon="view.getEditorButton(button).faIcon"
						:tooltip="view.getEditorButton(button).tooltip"
						@click="view.getEditorButton(button).action()"
					/>
				</div>
			</template>
		</EbToolbar>

		<div class="bg-white shadow-md h-[50.31px] px-8 flex flex-none w-full">
			<div class="flex space-x-6">
				<div
					v-for="tab in view.editorTabs"
					:key="tab"
					v-tippy="{ content: tab.active ? tab.tooltip : '' }"
					:class="view.state.view === tab.active ? 'text-blue-500 border-blue-500' : 'text-gray-400 border-transparent cursor-pointer hover:text-gray-500 hover:border-gray-500'"
					class="flex items-center h-full px-6 text-center transition-all border-b-2"
					@click="tab.action"
				>
					{{ tab.title }}
				</div>
			</div>
			<div
				v-if="state.platform.name === 'HTML' && view.state.view === EditorView.Split"
				class="flex ml-auto space-x-6"
			>
				<div
					v-tippy="{ content: view.getText('switch-to-preview') }"
					:class="view.state.isHTMLPreviewActive ? 'text-blue-500 border-blue-500' : 'text-gray-400 border-transparent cursor-pointer hover:text-gray-500 hover:border-gray-500'"
					class="flex items-center h-full px-6 text-center transition-all border-b-2"
					@click="view.state.isHTMLPreviewActive = true; view.state.isCodeWindowActive = false;"
				>
					{{ view.getText('preview') }}
				</div>
				<div
					v-tippy="{ content: view.getText('switch-to-html') }"
					:class="view.state.isCodeWindowActive ? 'text-blue-500 border-blue-500' : 'text-gray-400 border-transparent cursor-pointer hover:text-gray-500 hover:border-gray-500'"
					class="flex items-center h-full px-6 text-center transition-all border-b-2"
					@click="view.state.isCodeWindowActive = true; view.state.isHTMLPreviewActive = false;"
				>
					HTML
				</div>
			</div>
		</div>
		
		<div class="flex flex-col w-full h-full p-6">
			<div class="flex w-full h-full">
				<div
					v-show="view.state.isBlockEditorActive"
					class="w-full h-full bg-white border dark:border-gray-700 dark:bg-gray-800"
				>
					<Blockly />
				</div>
				<iframe
					v-if="view.state.isHTMLPreviewActive"
					class="w-full h-full max-w-md ml-4 bg-white border lg:max-w-lg dark:border-gray-700"
					:srcdoc="code"
				/>
				<div
					v-if="view.state.isCodeWindowActive"
					class="w-full h-full bg-white border dark:border-gray-700"
					:class="view.state.view === EditorView.Code ? '' : 'max-w-md lg:max-w-lg ml-4'"
				>
					<codemirror
						:value="code"
						:options="{
							mode: 'python',
							lineNumbers: true,
							theme: 'edublocks',
							readOnly: true
						}"
						class="w-full h-full"
					/>
				</div>
				<EbTrinket
					v-if="view.state.isTrinketWindowActive"
					class="w-full h-full max-w-md ml-4 bg-white border lg:max-w-lg"
				/>
				<div
					v-show="view.state.isRemoteTerminalWindowActive"
					id="terminal"
					class="w-full h-full max-w-md p-4 ml-4 bg-black bg-white border lg:max-w-lg"
				/>
			</div>
		</div>
		<EbModal
			:title="view.getText('publish-to-showcase')"
			:visible="view.state.isShowcasePublishModalOpen"
		>
			<template #content>
				<div class="space-y-4">
					<EbInput
						v-model="view.state.showcasePublishForm.title"
						:label="view.getText('title')"
						background="white"
					/>
					<EbInput
						v-model="view.state.showcasePublishForm.description"
						:label="view.getText('description')"
						background="white"
						textarea
					/>
					<h1 class="text-sm font-medium text-gray-900">
						{{ view.getText('thumbnail') }}
					</h1>
					<div class="flex items-center space-x-6">
						<div
							v-if="view.state.isThumbnailLoading"
							class="h-24 w-[160px] flex-none flex items-center justify-center bg-gray-100 shadow"
						>
							<div id="loading" />
						</div>
						<img
							v-else
							:src="view.state.showcasePublishForm.image"
							class="w-auto h-24 shadow"
						>
						<p class="text-sm text-gray-500">
							{{ view.getText('thumbnail-description') }}
						</p>
					</div>
				</div>
			</template>
			<template #buttons>
				<EbButton
					:text="view.getText('close')"
					color="lightBlue"
					@click="view.state.isShowcasePublishModalOpen = false"
				/>
				<EbButton
					v-if="!view.state.isThumbnailLoading"
					:text="view.getText('publish')"
					color="blue"
					@click="view.publishToShowcase()"
				/>
			</template>
		</EbModal>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { platforms } from "@/data/providers/platform-provider";
import { authentication } from "@/data/providers/authentication-provider";
import { View } from "@/views/constants";
import { state } from "@/data/providers/global-provider";
import { EditorModel } from "./editor-model";
import { code } from "./editor-state";
import Blockly from "@/components/Blockly/Blockly.vue";
import { EditorView, EditorButtons } from "./editor-type";
import { NavigationGuardNext, onBeforeRouteLeave, RouteLocationNormalized } from "vue-router";
import { codemirror } from "vue-codemirror-lite";
import "codemirror/mode/python/python";
import "@/assets/codemirror.css";

export default defineComponent({
	name: View.Editor,
	components: {
		Blockly,
		codemirror
	},
	setup() {
		const view: EditorModel = new EditorModel();

		onMounted(() => {
			view.checkForMode();
			view.switchView(EditorView.Split);
		});

		onBeforeRouteLeave((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
			if (!view.state.isSaved && state.platform) {
				if (confirm("Are you sure you want to leave without saving?")) {
					window.onbeforeunload = null;
					view.clear();
					state.assignmentModeActive = false;
					state.assignmentViewModeActive = false;
					state.isFirebaseProject = false;
					next();
				}
			}
			else {
				view.clear();
				state.assignmentModeActive = false;
				state.assignmentViewModeActive = false;
				state.isFirebaseProject = false;
				next();
			}
		});

		return { view, platforms, state, EditorView, code, EditorButtons, authentication };
	}
});
</script>
