import { FirebaseFile, getAllProjectsAsync, getLocalDataForFile } from "@/data/providers/files-provider";
import { state } from "@/data/providers/global-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";
import { appInsights } from "@/main";
import router from "@/router";
import { xmlCode } from "@/views/editor/editor-state";
import { ref, Ref, toRaw } from "@vue/reactivity";
import { SearchResult } from "./eb-search-types";

export const searchTerm: Ref<string> = ref("");

export class EbSearch {
	/**
	 * Get Translated text for the search component
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("search", key);
	}

	/**
	 * Open a project in the editor
	 */
	public openProject(project: FirebaseFile): void {
		fetch(project.downloadURL).then((response: Response) => {
			return response.blob();
		}).then((blob: Blob) => {
			blob.text().then((xml: string) => {
				xmlCode.value = xml;
				state.filename = getLocalDataForFile(project).shortTitle;
				state.platform = getLocalDataForFile(project).platform;
				appInsights?.trackEvent({
					name: `Load ${state.platform?.name as string} Mode`
				});
				state.isFirebaseProject = true;
				router.push({path: "/editor"});
			});
		});
	}

	public getAllFilesFromFirebase(): void {
		getAllProjectsAsync().then((files: firebase.default.storage.ListResult | undefined) => {
			if (files) {
				files.items.forEach((ref: firebase.default.storage.Reference) => {
					ref.getDownloadURL().then((url: string) => {
						ref.getMetadata().then((meta: firebase.default.storage.FullMetadata) => {
							const file: FirebaseFile = {label: ref.name, ref: toRaw(ref), downloadURL: url, metadata: meta, date: new Date(meta.updated)};
							this.searchResults.push({title: `${getLocalDataForFile(file).shortTitle as string} (${getLocalDataForFile(file).platform?.name as string})`, subtitle: this.getText("open-project"), action: () => {
								this.openProject(file);
								state.isSearchOpen = false;
							}});
						});
					});
				});
			}
		});
	}

	public searchResults: Array<SearchResult> = [
		{ 
			title: getTranslatedText("navigation", "home"), 
			subtitle: getTranslatedText("search", "go-to-page"), 
			action: (): void => {
				router.push({path: "/"}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("navigation", "editor"), 
			subtitle: getTranslatedText("search", "go-to-page"), 
			action: (): void => {
				router.push({path: "/editor"}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("navigation", "projects"), 
			subtitle: getTranslatedText("search", "go-to-page"), 
			action: (): void => {
				router.push({path: "/projects"}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("navigation", "showcase"), 
			subtitle: getTranslatedText("search", "go-to-page"), 
			action: (): void => {
				router.push({path: "/showcase"}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("navigation", "guides"), 
			subtitle: getTranslatedText("search", "go-to-page"), 
			action: (): void => {
				router.push({path: "/guides"}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("navigation", "classroom"), 
			subtitle: getTranslatedText("search", "go-to-page"), 
			action: (): void => {
				router.push({path: "/classroom"}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("navigation", "home"), 
			subtitle: getTranslatedText("search", "go-to-page"), 
			action: (): void => {
				router.push({path: "/settings"}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("search", "create-python-project"), 
			subtitle: getTranslatedText("navigation", "editor"), 
			action: (): void => {
				router.push({path: "/editor", query: { mode: "python" }}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("search", "create-microbit-project"), 
			subtitle: getTranslatedText("navigation", "editor"), 
			action: (): void => {
				router.push({path: "/editor", query: { mode: "microbit" }}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("search", "create-circuitpython-project"), 
			subtitle: getTranslatedText("navigation", "editor"), 
			action: (): void => {
				router.push({path: "/editor", query: { mode: "circuitpython" }}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("search", "create-pi-project"), 
			subtitle: getTranslatedText("navigation", "editor"), 
			action: (): void => {
				router.push({path: "/editor", query: { mode: "rpi" }}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("search", "create-html-project"), 
			subtitle: getTranslatedText("navigation", "editor"), 
			action: (): void => {
				router.push({path: "/editor", query: { mode: "html" }}); 
				state.isSearchOpen = false;
			} 
		},
		{ 
			title: getTranslatedText("search", "go-fullscreen"), 
			subtitle: getTranslatedText("search", "action"), 
			action: (): void => {
				document.documentElement.requestFullscreen();
				state.isSearchOpen = false;
			} 
		},
	];
}