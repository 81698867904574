import { createApp, ComponentPublicInstance } from "vue";
import App from "./App.vue";
import router from "./router";
import Components from "./components/index";
import Icons from "./data/providers/icon-provider";
import { loadPreferredLanguageAsync } from "./data/providers/localization-provider";
import { clickoutside } from "@/utilities/events";
import { authentication } from "@/data/providers/authentication-provider";
import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css"; 
import "tippy.js/animations/shift-away.css";
import flagsmith from "flagsmith";
import "@/assets/tailwind.css";
import "vue-toastification/dist/index.css";
import "xterm/css/xterm.css";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
// import * as firebase from "firebase/app";

let app: ComponentPublicInstance | undefined;

export let appInsights: ApplicationInsights | undefined;

const options: PluginOptions = {
	position: POSITION.TOP_CENTER
};

function startApp(): void {
	appInsights = new ApplicationInsights({config: {
		instrumentationKey: process.env.VUE_APP_INSTRUMENTATION_KEY,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true
	} });
	appInsights.loadAppInsights();
	appInsights.trackPageView();
	appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
		if (envelope.tags) {
			envelope.tags["ai.cloud.role"] = location.hostname;
		}
	});

	app = createApp(App)
		.use(router)
		.use(Components)
		.use(Icons)
		.use(Toast, options)
		.component("Splide", Splide)
		.component("SplideSlide", SplideSlide)
		.use(VueTippy, {
			defaultProps: { placement: "bottom", animation: "shift-away" },
		})
		.directive("click-outside", clickoutside)
		.mount("#app");
}

authentication.auth.onAuthStateChanged(() => {
	if (!app) {
		flagsmith.init({
			environmentID: location.host === "localhost:8080" ? "PaR3ZurcFy7jiRZD3B3eLo" : "" || location.host === "staging.edublocks.org" ? "FDf7Mb8yiPgKcPywEuVgEv" : "" || location.host === "app.edublocks.org" ? "mMH2BuLhtrMHby86FKKeKK" : "",
		}).then(() => {
			loadPreferredLanguageAsync().finally(startApp);
		});
	}
});