// Navigation Item type
export interface NavigationItem {
	key: string,
	icon: string,
	path: string
}

// Define array of navigation items
export const defaultNavigation: Array<NavigationItem> = [
	{ key: "home", icon: "HomeIcon", path: "/" },
	{ key: "editor", icon: "CodeIcon", path: "/editor" },
	{ key: "projects", icon: "FolderIcon", path: "/projects" },
	{ key: "showcase", icon: "FilmIcon", path: "/showcase" },
	{ key: "guides", icon: "BookOpenIcon", path: "/guides" },
	{ key: "classroom", icon: "UserGroupIcon", path: "/classroom" },
	// { key: "settings", icon: "CogIcon", path: "/settings" }
	{ key: "feedback", icon: "ChatAlt2Icon", path: "/feedback" }
];