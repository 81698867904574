<template>
	<!-- Editor -->
	<div
		class="flex flex-col w-full h-full"
	>
		<div class="bg-white shadow-md h-[60px] px-4 flex space-x-6 flex-none items-center w-full">
			<div
				v-if="state.platform?.name === 'HTML'"
				v-tippy="{ content: 'Switch to Preview Only' }"
				:class="view.state.view === EditorView.Preview ? 'text-blue-500 border-blue-500' : 'text-gray-400 border-transparent cursor-pointer hover:text-gray-500 hover:border-gray-500'"
				class="flex items-center h-full px-6 text-center transition-all border-b-2"
				@click="view.switchView(EditorView.Preview)"
			>
				Preview
			</div>
			<div
				v-for="tab in view.editorTabs.slice(1, 3)"
				:key="tab"
				v-tippy="{ content: tab.active ? tab.tooltip : '' }"
				:class="view.state.view === tab.active ? 'text-blue-500 border-blue-500' : 'text-gray-400 border-transparent cursor-pointer hover:text-gray-500 hover:border-gray-500'"
				class="flex items-center h-full px-6 text-center transition-all border-b-2"
				@click="tab.action"
			>
				{{ tab.title }}
			</div>
			<div class="!ml-auto">
				<EbButton
					v-for="button in state.platform.actions.slice(-1)"
					:key="button"
					:text="view.getEditorButton(button).text"
					:color="view.getEditorButton(button).color"
					:icon="view.getEditorButton(button).icon"
					:fa-icon="view.getEditorButton(button).faIcon"
					:tooltip="view.getEditorButton(button).tooltip"
					@click="view.getEditorButton(button).action()"
				/>
			</div>
		</div>
		
		<div class="flex flex-col w-full h-full">
			<div class="flex w-full h-full">
				<div
					v-show="view.state.isBlockEditorActive"
					class="w-full h-full bg-white border dark:border-gray-700 dark:bg-gray-800"
				>
					<Blockly />
				</div>
				<iframe
					v-if="view.state.isHTMLPreviewActive"
					class="w-full bg-white border dark:border-gray-700"
					:srcdoc="code"
				/>
				<div
					v-if="view.state.isCodeWindowActive"
					class="w-full h-full bg-white border dark:border-gray-700"
					:class="view.state.view === EditorView.Code ? '' : 'max-w-md lg:max-w-lg ml-4'"
				>
					<codemirror
						:value="code"
						:options="{
							mode: 'python',
							lineNumbers: true,
							theme: 'edublocks',
							readOnly: true
						}"
						class="w-full h-full"
					/>
				</div>
				<EbTrinket
					v-if="view.state.isTrinketWindowActive"
					class="w-full h-full bg-white border"
				/>
				<div
					v-show="view.state.isRemoteTerminalWindowActive"
					id="terminal"
					class="w-full h-full max-w-md p-4 ml-4 bg-black bg-white border lg:max-w-lg"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { platforms } from "@/data/providers/platform-provider";
import { View } from "@/views/constants";
import { state } from "@/data/providers/global-provider";
import { EditorModel } from "./editor-model";
import { code } from "./editor-state";
import Blockly from "@/components/Blockly/Blockly.vue";
import { EditorView, EditorButtons } from "./editor-type";
import { codemirror } from "vue-codemirror-lite";
import "codemirror/mode/python/python";
import "@/assets/codemirror.css";

export default defineComponent({
	name: View.Editor,
	components: {
		Blockly,
		codemirror
	},
	setup() {
		const view: EditorModel = new EditorModel();

		onMounted(() => {
			state.isSharedProject = true;
			if (state.platform?.name === "HTML" && !state.screenshotMode) {
				view.switchView(EditorView.Preview);
			}
			else {
				view.switchView(EditorView.Blocks);
			}

			if (state.platform?.name === "Python 3" && !state.screenshotMode) {
				view.runPythonCode();
			}
		});

		return { view, platforms, state, EditorView, code, EditorButtons };
	}
});
</script>
