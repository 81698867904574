import { state } from "./global-provider";

let textLookupTable: Record<string, string> = {};

interface Language {
	name: string,
	icon: string,
	code: string
}

export const languages: Array<Language> = [
	{ name: "english", icon: "GB", code: "en" },
	{ name: "french", icon: "FR", code: "fr" },
];

export function getCurrentLanguageIcon(): string {
	let icon: string = "";
	languages.forEach((language: Language) => {
		if (language.code === state.language) {
			icon = language.icon;
		}
	});
	return icon;
}

export function getTranslatedText(name: string, key: string): string {
	const target: string = `${name}.${key}`;
	const result: string = textLookupTable[target];

	if (result === undefined || result === null)	{
		return `!! ${target} !!`;
	}

	return result;
}

function mergeNewLanguage(data: Record<string, string>): void {
	for (const key of Object.keys(data)) {
		textLookupTable[key] = data[key];
	}
}

async function loadLanguage(code: string): Promise<Record<string, string>> {
	let result: Record<string, string> = {};

	const response: Response = await fetch(`/languages/${code}.json`);

	if (response.ok) {
		result = await response.json() as Record<string, string>;
	}

	return result;
}

export async function loadPreferredLanguageAsync(): Promise<boolean> {
	const defaultLanguage: string = "en";

	if (localStorage.getItem("language")) {
		state.language = localStorage.getItem("language") as string;
	}

	const supportedLanguages: Array<string> = ["en", "fr"];

	let preferredLanguage: string = defaultLanguage;

	if (supportedLanguages.includes(state.language)) {
		preferredLanguage = state.language;
	}

	textLookupTable = await loadLanguage(defaultLanguage);

	if (preferredLanguage !== defaultLanguage) {
		const alternativeLanguageData: Record<string, string> = await loadLanguage(preferredLanguage);
		mergeNewLanguage(alternativeLanguageData);
	}

	return true;
}