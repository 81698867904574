/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { authentication } from "./authentication-provider";
import * as firebase from "firebase/app";
import { Platform, platforms } from "./platform-provider";
import { state } from "./global-provider";
import { code, xmlCode } from "@/views/editor/editor-state";
import { saveAs } from "file-saver";

export interface FirebaseFile {
	label: string;
	ref: firebase.default.storage.Reference;
	downloadURL: string;
	metadata?: firebase.default.storage.FullMetadata;
	date: Date;
}

export interface FileLocalData {
	platform?: Platform;
	shortTitle?: string;
} 

export async function getAllProjectsAsync(): Promise<firebase.default.storage.ListResult | undefined> {
	let files: firebase.default.storage.ListResult | undefined;
	if (authentication.currentUser.value) {
		const ref: firebase.default.storage.Reference = firebase.default.storage().ref(`blocks/${authentication.currentUser.value.uid}`);
		await ref.listAll().then((res: firebase.default.storage.ListResult) => {
			files = res;
		});
	} 
	return files;
}

export function getLocalDataForFile(file: FirebaseFile): FileLocalData {
	const data: FileLocalData = {};
	platforms.forEach((platform: Platform) => {
		if (file.label.includes(`(${platform.key})`)) {
			data.shortTitle = file.label.replace(`(${platform.key})`, "");
			data.platform = platform;
		}
	});
	return data;
}

export async function deleteFirebaseFileAsync(ref: firebase.default.storage.Reference): Promise<void> {
	const metadata: any = await ref.getMetadata();
	if (metadata.customMetadata && metadata.customMetadata.deleteDisabled === "true") {
		throw Error("Delete functionality locked for this project");
	}
	else {
		await ref.delete();
	}
}

export async function saveFileToFirebaseAsync(): Promise<void> {
	if (authentication.currentUser.value) {
		const ref: firebase.default.storage.Reference = firebase.default.storage().ref(`blocks/${authentication.currentUser.value.uid}/${state.filename?.trim()} (${state.platform?.key})`);
		state.currentProject = ref;
		state.isFirebaseProject = true;
		await ref.putString(xmlCode.value);
	}
}

export function saveLocalFile(): void {
	const blob: Blob = new Blob([xmlCode.value], {type: "text/xml;charset=utf-8"});
	let file: string = "untitled.xml";
	if (state.filename) {
		file = `${state.filename.trimEnd()}.xml`;
	}
	saveAs(blob, file);
}

export function savePythonFile(): void {
	const blob: Blob = new Blob([code.value], {type: "text/python;charset=utf-8"});
	let file: string = "untitled.py";
	if (state.filename) {
		file = `${state.filename.trimEnd()}.py`;
	}
	saveAs(blob, file);
}

export async function openLocalFile(): Promise<string> {
	return new Promise<string>((resolve: any, reject: any) => {
		function readSingleFile(e: Event): void {
		  const file: Blob = (e.target as any).files[0];
		  const name: string = (e.target as any).files[0].name;
		  if (!file) {
				return;
			}
	
		  const reader: FileReader = new FileReader();
	
		  reader.onload = (e: ProgressEvent<FileReader>): void => {
				const contents: string = (e.target as any).result;
				resolve(contents);
				state.filename = name.replace(".xml", "");
		  };
	
		  reader.onerror = (): void => {
				reject(new Error("Reader error"));
		  };
	
		  reader.readAsText(file);
		}
	
		const fileInput: HTMLInputElement = document.createElement("input");
		fileInput.type = "file";
		fileInput.accept = ".xml";
		fileInput.addEventListener("change", readSingleFile, false);
		fileInput.click();
	  });
}