import { reactive } from "vue";
import { Platform } from "./platform-provider";

class GlobalState {
	public platform: Platform | undefined;
	public language: string = "en";
	public filename: string | undefined = "Untitled Project";
	public assignmentModeActive: boolean = false;
	public assignmentViewModeActive: boolean = false;
	public isSearchOpen: boolean = false;
	public isSharedProject: boolean = false;
	public screenshotMode: boolean = false;
	public currentProject: firebase.default.storage.Reference | undefined;
	public isFirebaseProject: boolean = false;
}

export const state: GlobalState = reactive(new GlobalState);

/**
 * Format date to global format
 * @param {string|undefined} dateString
 * @returns string
 */
export function formatDate(dateString: string | undefined): string {
	let formattedDate: string = "";

	const today: Date = new Date();
	const yesterday: Date = new Date(today);
	yesterday.setDate(yesterday.getDate() -1);

	if (dateString) {
		const date: Date = new Date(dateString);

		if (date.toDateString() === today.toDateString()) {
			formattedDate = "Today at " + date.toLocaleTimeString().slice(0, -3);
		}

		else if (date.toDateString() === yesterday.toDateString()) {
			formattedDate = "Yesterday at " + date.toLocaleTimeString().slice(0, -3);
		}

		else {
			formattedDate = date.toLocaleString().slice(0, -3);
		}
	}

	return formattedDate;
}

export function getGradient(): string {
	const gradients: Array<string> = [
		"bg-gradient-to-r to-indigo-500 from-purple-500",
		"bg-gradient-to-r from-amber-300 to-orange-500",
		"bg-gradient-to-r from-green-400 to-cyan-500",
		"bg-gradient-to-r from-fuchsia-500 to-purple-600",
		"bg-gradient-to-r from-lightBlue-400 to-indigo-500",
		"bg-gradient-to-r from-orange-400 to-pink-600",
		"bg-gradient-to-r from-cyan-400 to-lightBlue-500"
	];

	return gradients[Math.floor(Math.random()*gradients.length)];
}