/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { getTranslatedText } from "@/data/providers/localization-provider";
import { reactive } from "vue";
import { ClassroomAssignmentState } from "./classroom-assignment-state";
import * as firebase from "firebase/app";
import { authentication } from "@/data/providers/authentication-provider";
import router from "@/router";
import { Tab } from "@/components/eb-selector/eb-selector";
import { FirebaseFile, getLocalDataForFile } from "@/data/providers/files-provider";
import { xmlCode } from "@/views/editor/editor-state";
import { state } from "@/data/providers/global-provider";
import { appInsights } from "@/main";

export class ClassroomAssignmentModel {
	// State for Classroom Assignment View
	public state: ClassroomAssignmentState = reactive(new ClassroomAssignmentState());

	/**
	 * Get Translated text for the classroom view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("classroom", key);
	} 

	public init(): void {
		this.getClassroom();
		this.getAssignment();
	}

	public getAssignment(): void {
		authentication.db.collection("classrooms").doc(router.currentRoute.value.params.classID as string).collection("assignments").doc(router.currentRoute.value.params.assignmentID as string).get().then((doc: firebase.default.firestore.DocumentSnapshot) => {
			this.state.assignment = {id: doc.id, data: doc.data()};
			this.state.starterCode = JSON.parse(this.state.assignment?.data.project);
			this.getAssignmentSubmission();
			this.getSubmittedAssignments();
		});
	}

	public getClassroom(): void {
		authentication.db.collection("classrooms").doc(router.currentRoute.value.params.classID as string).get().then((doc: firebase.default.firestore.DocumentSnapshot) => {
			this.state.classroom = {id: doc.id, data: doc.data()};
			this.state.classroom?.data.students.forEach((student: string) => {
				authentication.getUserDetails(student).then((data: firebase.default.firestore.DocumentData | undefined) => {
					this.state.students.push(data);
				});
			});
		});
	}

	public isUserAdmin(): boolean {
		let isUserAdmin: boolean = false;
		if (this.state.classroom?.data.admins.includes(authentication.currentUser.value?.uid)) {
			isUserAdmin = true;
		}
		if (this.state.classroom?.data.students.includes(authentication.currentUser.value?.uid)) {
			isUserAdmin = false;
		}
		return isUserAdmin;
	}

	public submitAssignment(): void {
		authentication.db.collection("classrooms").doc(this.state.classroom?.id).collection("assignments").doc(this.state.assignment?.id).collection("submissions").doc(this.state.submission?.id).update({
			submitted: true,
			studentComments: this.state.studentComments
		}).then(() => {
			this.init();
		});
	}
	
	public openAssignmentCode(): void {
		authentication.db.collection("classrooms").doc(this.state.classroom?.id).collection("assignments").doc(this.state.assignment?.id).collection("submissions").where("IDs", "==", {assignmentID: this.state.assignment?.id, uid: authentication.currentUser.value?.uid}).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			if (snapshot.docs.length > 0) {
				snapshot.forEach((doc: firebase.default.firestore.QueryDocumentSnapshot) => {
					if (doc.data()) {
						xmlCode.value = doc.data().xmlCode;
						if (this.state.starterCode) {
							state.platform = getLocalDataForFile(this.state.starterCode).platform;
							appInsights?.trackEvent({
								name: `Load ${state.platform?.name as string} Mode`
							});
						}
						state.assignmentModeActive = true;
						router.push({path: "/editor", query: { assignmentID: this.state.assignment?.id, classroomID: this.state.classroom?.id }});
					}
				});
			}
			else {
				const starterCode: FirebaseFile = JSON.parse(this.state.assignment?.data.project);
				state.assignmentModeActive = true;
				this.openProject(starterCode);
			}
		});
	}

	public getAssignmentSubmission(): void {
		this.state.submission = undefined;
		this.state.studentComments = "";
		authentication.db.collection("classrooms").doc(this.state.classroom?.id).collection("assignments").doc(this.state.assignment?.id).collection("submissions").where("IDs", "==", {assignmentID: this.state.assignment?.id, uid: authentication.currentUser.value?.uid}).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			if (snapshot.docs.length > 0) {
				snapshot.forEach((doc: firebase.default.firestore.QueryDocumentSnapshot) => {
					if (doc.data()) {
						this.state.submission = {id: doc.id, data: doc.data()};
						this.state.studentComments = this.state.submission.data.studentComments;
						if (this.state.submission?.data.submitted) {
							this.state.selected = "submission";
						}
					}
				});
			}
		});
	}

	public getSubmittedAssignments(): void {
		if (this.isUserAdmin()) {
			authentication.db.collection("classrooms").doc(this.state.classroom?.id).collection("assignments").doc(this.state.assignment?.id).collection("submissions").where("submitted", "==", true).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
				console.log(snapshot);
				if (snapshot.docs.length > 0) {
					snapshot.forEach((doc: firebase.default.firestore.QueryDocumentSnapshot) => {
						if (doc.data()) {
							this.state.submittedAssignments.push({id: doc.id, data: doc.data()});
						}
					});
				}
			});
		}
	}

	public async deleteAssignment(): Promise<void> {
		await authentication.db.collection("classrooms").doc(router.currentRoute.value.params.classID as string).update({
			assignments: firebase.default.firestore.FieldValue.arrayRemove(this.state.assignment?.id)
		}).then(() => {
			router.push({path: `/classroom/${this.state.classroom?.id}`});
		});
	}

	public getStudentsWithNoSubmission(): Array<string> {
		const studentIDs: Array<string> = [];
		const assignmentIDs: Array<string> = [];
		const studentsWithNoSubmissions: Array<string> = [];
		
		this.state.students.forEach((student: firebase.default.firestore.DocumentData | undefined) => {
			studentIDs.push(student?.uid);
		});

		this.state.submittedAssignments.forEach((assignment: firebase.default.firestore.DocumentData | undefined) => {
			assignmentIDs.push(assignment?.data.IDs.uid);
		});

		studentIDs.forEach((id: string) => {
			if (!assignmentIDs.includes(id)) {
				studentsWithNoSubmissions.push(id);
			}
		});

		return studentsWithNoSubmissions;
	}

	public openProject(project: FirebaseFile): void {
		fetch(project.downloadURL).then((response: Response) => {
			return response.blob();
		}).then((blob: Blob) => {
			blob.text().then((xml: string) => {
				xmlCode.value = xml;
				state.currentProject = project.ref;
				state.platform = getLocalDataForFile(project).platform;
				appInsights?.trackEvent({
					name: `Load ${state.platform?.name as string} Mode`
				});
				router.push({path: "/editor", query: { assignmentID: this.state.assignment?.id, classroomID: this.state.classroom?.id }});
			});
		});
	}

	public openExistingProject(project: FirebaseFile): void {
		fetch(project.downloadURL).then((response: Response) => {
			return response.blob();
		}).then((blob: Blob) => {
			blob.text().then((xml: string) => {
				xmlCode.value = xml;
				state.filename = getLocalDataForFile(project).shortTitle;
				state.platform = getLocalDataForFile(project).platform;
				appInsights?.trackEvent({
					name: `Load ${state.platform?.name as string} Mode`
				});
				router.push({path: "/editor"});
			});
		});
	}

	public openSubmissionCode(submissionXML: string, project: FirebaseFile): void {
		state.assignmentViewModeActive = true;
		xmlCode.value = submissionXML;
		state.platform = getLocalDataForFile(project).platform;
		appInsights?.trackEvent({
			name: `Load ${state.platform?.name as string} Mode`
		});
		router.push({path: "/editor"});
	}

	public findStudent(id: string): firebase.default.firestore.DocumentData {
		let studentInfo: firebase.default.firestore.DocumentData = {};
		this.state.students.forEach((student: firebase.default.firestore.DocumentData | undefined) => {
			if (student?.uid === id) {
				studentInfo = student;
			}
		});
		return studentInfo;
	}

	public editSubmission(id: string): void {
		authentication.db.collection("classrooms").doc(this.state.classroom?.id).collection("assignments").doc(this.state.assignment?.id).collection("submissions").doc(id).update(this.state.submittedAssignments[0]?.data).then(() => {
			this.state.isSubmissionModalActive = false;
		});
	}

	public openSubmissionModal(index: number): void {
		this.state.activeSubmission = index;
		this.state.isSubmissionModalActive = true;
	}

	public getTabs(): Array<Tab> {
		return [
			{
				title: this.getText("submission"), 
				key: "submission", 
				visible: this.state.submission?.data.submitted,
				action: (): void => {
					this.state.selected = "submission";
				}
			},
			{
				title: this.getText("assignment-details"), 
				key: "assignment-details", 
				visible: true,
				action: (): void => {
					this.state.selected = "assignment-details";
				}
			},
			{
				title: this.getText("student-work"), 
				key: "student-work", 
				count: this.state.submittedAssignments.length,
				visible: this.isUserAdmin(),
				action: (): void => {
					this.state.selected = "student-work";
				}
			}
		];
	}
} 