<template>
	<div
		v-if="view.state.classroom"
		class="flex flex-col w-full h-full"
	>
		<div class="flex w-full h-full space-x-6">
			<div class="flex flex-col flex-1 w-full h-full">
				<EbPageHeader
					:title="view.state.classroom?.data.name"
					no-border
					back-arrow
					@arrowClick="this.$router.push({path: '/classroom'})"
				>
					<div class="space-x-4">
						<EbButton
							:text="view.getText('delete-classroom')"
							icon="TrashIcon"
							:tooltip="`${view.getText('delete')} ${view.state.classroom?.data.name}`"
							color="red"
							@click="view.deleteClassroom()"
						/>
						<EbButton
							:text="view.getText('new-assignment')"
							icon="PlusIcon"
							color="blue"
							:tooltip="view.getText('new-assignment')"
							@click="view.state.isCreateAssignmentModalOpen = true"
						/>
					</div>
				</EbPageHeader>
				<EbSelector
					:items="view.getTabs()"
					selected="active-assignments"
				/>
				<div
					v-if="view.state.selected === 'active-assignments'"
					class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
				>
					<EbThumbnailCard
						v-for="assignment in view.state.activeAssignments"
						:key="assignment"
						:title="assignment.data.title"
						:visible="view.isAssignmentActive(assignment.data.due)"
						:subtitle="`${view.getText('due')} - ${new Date(assignment.data.due).toDateString()}`"
						:gradient="assignment.data.gradient"
						:gradient-image="`/assets/images/calendar/${new Date(assignment.data.due).getDate()}.svg`"
						@click="this.$router.push({path: `/classroom/${this.$route.params.id}/assignment/${assignment.id}`})"
					/>
				</div>

				<div
					v-if="view.state.selected === 'active-assignments' && view.state.activeAssignments.length === 0"
					class="flex flex-col items-center justify-center w-full h-full mx-auto text-center"
				>
					<img
						src="/assets/images/general/sad-window.svg"
						class="mb-6 h-36 justify-self-center"
					>
					<h1 class="mb-2 text-2xl font-medium text-gray-500">
						{{ view.getText('no-assignments') }}
					</h1>
					<h1 class="text-gray-400 text-md">
						{{ view.getText('no-active-assignments-description') }}
					</h1>
				</div>

				<div
					v-if="view.state.selected === 'past-assignments'"
					class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
				>
					<EbThumbnailCard
						v-for="assignment in view.state.pastAssignments"
						:key="assignment"
						:title="assignment.data.title"
						:visible="!view.isAssignmentActive(assignment.data.due)"
						:subtitle="`${view.getText('due')} - ${new Date(assignment.data.due).toDateString()}`"
						:gradient="assignment.data.gradient"
						:gradient-image="`/assets/images/calendar/${new Date(assignment.data.due).getDate()}.svg`"
						@click="this.$router.push({path: `/classroom/${this.$route.params.id}/assignment/${assignment.id}`})"
					/>
				</div>

				<div
					v-if="view.state.selected === 'past-assignments' && view.state.pastAssignments.length === 0"
					class="flex flex-col items-center justify-center w-full h-full mx-auto text-center"
				>
					<img
						src="/assets/images/general/sad-window.svg"
						class="mb-6 h-36 justify-self-center"
					>
					<h1 class="mb-2 text-2xl font-medium text-gray-500">
						{{ view.getText('no-assignments') }}
					</h1>
					<h1 class="text-gray-400 text-md">
						{{ view.getText('no-past-assignments-description') }}
					</h1>
				</div>
			</div>
			<div class="w-full h-full max-w-xs px-6 pb-6 ml-4 space-y-6 overflow-y-auto bg-white rounded-md shadow-xl dark:bg-gray-800 dark:border-gray-800">
				<div>
					<h3 class="sticky top-0 pt-6 pb-4 font-medium text-gray-900 bg-white border-b border-gray-200 dark:text-white">
						{{ view.isUserAdmin() ? view.getText('students') : 'Your Class' }}
					</h3>
					
					<ul class="mt-2 dark:border-gray-900">
						<li
							v-for="student in view.state.students"
							:key="student"
							class="flex items-center justify-between py-3"
						>
							<div class="flex items-center">
								<img
									:src="student.image"
									class="w-8 h-8 rounded-full"
								>
								<p class="ml-4 text-sm font-medium text-gray-900 dark:text-white">
									{{ student.name }}
								</p>
							</div>
							<TrashIcon
								v-if="view.isUserAdmin()"
								class="w-4 h-4 ml-3 text-red-500 cursor-pointer hover:text-red-700"
								@click="view.removeStudentFromClass(student.uid)"
							/>
						</li>
						<li
							v-if="view.isUserAdmin()"
							class="flex items-center justify-between py-2"
						>
							<div
								class="flex items-center p-1 -ml-1 cursor-pointer group"
								@click="view.getClassroomJoinCode()"
							>
								<div class="relative w-8 h-8 transition-all">
									<span class="flex items-center justify-center w-8 h-8 text-gray-400 border-2 border-gray-300 border-dashed rounded-full group-hover:animate-spin-slow" />
									<PlusIcon class="absolute top-1.5 w-5 h-5 left-1.5 text-gray-500 group-hover:text-blue-500 dark:text-gray-400" />
								</div>
								<span class="ml-4 text-sm font-medium text-blue-500 group-hover:text-blue-600">{{ view.getText('add-students') }}</span>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<EbModal
		:title="view.getText('new-assignment')"
		:visible="view.state.isCreateAssignmentModalOpen"
	>
		<template #content>
			<div class="space-y-4">
				<EbInput
					v-model="view.newAssignmentFormData.title"
					:label="view.getText('name')"
					background="white"
				/>
				<EbInput
					v-model="view.newAssignmentFormData.description"
					:label="view.getText('description')"
					background="white"
					textarea
				/>
				<EbInput
					v-model="view.newAssignmentFormData.due"
					:label="view.getText('due-date')"
					background="white"
					type="datetime-local"
				/>
				<EbInput
					v-model="view.newAssignmentFormData.marks"
					:label="view.getText('total-marks')"
					background="white"
					type="number"
				/>
				<EbSelect
					:label="view.getText('starter-project')"
					:options="view.state.files"
					@selected="view.newAssignmentFormData.project = $event"
				/>
			</div>
		</template>
		<template #buttons>
			<EbButton
				:text="view.getText('close')"
				color="lightBlue"
				@click="view.state.isCreateAssignmentModalOpen = false"
			/>
			<EbButton
				:text="view.getText('create')"
				color="blue"
				@click="view.createNewAssignment()"
			/>
		</template>
	</EbModal>

	<EbModal
		:title="view.getText('add-students')"
		:visible="view.state.isAddStudentsModalOpen"
	>
		<template #content>
			<h1 class="w-full font-semibold text-center text-9xl">
				{{ view.state.joinCode }}
			</h1>
		</template>
		<template #buttons>
			<EbButton
				:text="view.getText('close')"
				color="lightBlue"
				@click="view.state.isAddStudentsModalOpen = false"
			/>
		</template>
	</EbModal>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { ClassroomModel } from "./classroom-detail-model";

export default defineComponent({
	name: View.ClassroomDetail,
	setup() {
		const view: ClassroomModel = new ClassroomModel();

		onMounted(() => {
			view.init();
		});

		return { view };
	}
});
</script>
