<template>
	<div
		v-if="view.state.project"
		class="flex flex-col w-full h-full space-y-4"
	>
		<EbPageHeader
			:title="view.state.project.title"
			back-arrow
			@arrowClick="this.$router.push({path: `/showcase`})"
		>
			<div class="flex items-center space-x-4">
				<div
					v-if="view.state.user"
					class="flex items-center px-4 border-r"
				>
					<img
						class="mr-3 rounded-full h-7"
						:src="view.state.user.image"
					>
					<span class="font-medium text-gray-900">{{ view.state.user.name }}</span>
					<SBadgeCheck
						v-if="view.state.user.verified"
						v-tippy="{ content: 'Verified User' }"
						class="h-5 ml-1 text-blue-500"
					/>
				</div>
				<EbButton
					v-if="view.state.isUserAuthor"
					:text="view.getText('delete')"
					color="red"
					icon="TrashIcon"
					@click="view.deleteProject()"
				/>
				<EbButton
					:text="view.getText('clone')"
					color="blue"
					fa-icon="fas fa-redo"
					@click="view.goToUrl(view.state.project?.cloneURL)"
				/>
			</div>
		</EbPageHeader> 
		<div class="flex w-full h-full space-x-6">
			<iframe
				class="w-full h-full bg-white rounded-md shadow-lg"
				:src="view.getURL(view.state.project.projectURL)"
			/>
			<div class="flex flex-col w-full h-full max-w-md p-6 space-y-4 bg-white rounded-md shadow-lg">
				<div class="w-full h-2/5">
					<div class="pb-4 mb-4 border-b border-gray-200">
						<h3 class="font-medium leading-6 text-gray-900 text-md">
							{{ view.getText('description') }}
						</h3>
					</div>
					<div class="overflow-y-auto text-sm text-gray-700 whitespace-pre-wrap h-[78%]">
						{{ view.state.project.description }}
					</div>
				</div>
				<div class="w-full h-[56%]">
					<div class="pb-4 mb-4 border-b border-gray-200">
						<h3 class="font-medium leading-6 text-gray-900 text-md">
							{{ view.getText('comments') }}
						</h3>
					</div>
					<div class="flex items-center justify-center w-full h-full">
						<div class="flex flex-col space-y-4 text-center">
							<img
								src="/assets/images/general/clock.svg"
								class="h-20 justify-self-center"
							>
							<h1 class="mb-2 text-xl font-medium text-gray-500">
								{{ view.getText('coming-soon') }}!
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { ShowcaseProjectModel } from "./showcase-project-model";
import { View } from "../../constants";

export default defineComponent({
	name: View.ShowcaseProject,
	setup() {
		const view: ShowcaseProjectModel = new ShowcaseProjectModel();

		onMounted(() => {
			view.init();
		});

		return { view };
	}
});
</script>