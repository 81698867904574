
import { defineComponent, onMounted } from "vue";
import { platforms } from "@/data/providers/platform-provider";
import { authentication } from "@/data/providers/authentication-provider";
import { View } from "@/views/constants";
import { state } from "@/data/providers/global-provider";
import { EditorModel } from "./editor-model";
import { code } from "./editor-state";
import Blockly from "@/components/Blockly/Blockly.vue";
import { EditorView, EditorButtons } from "./editor-type";
import { NavigationGuardNext, onBeforeRouteLeave, RouteLocationNormalized } from "vue-router";
import { codemirror } from "vue-codemirror-lite";
import "codemirror/mode/python/python";
import "@/assets/codemirror.css";

export default defineComponent({
	name: View.Editor,
	components: {
		Blockly,
		codemirror
	},
	setup() {
		const view: EditorModel = new EditorModel();

		onMounted(() => {
			view.checkForMode();
			view.switchView(EditorView.Split);
		});

		onBeforeRouteLeave((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
			if (!view.state.isSaved && state.platform) {
				if (confirm("Are you sure you want to leave without saving?")) {
					window.onbeforeunload = null;
					view.clear();
					state.assignmentModeActive = false;
					state.assignmentViewModeActive = false;
					state.isFirebaseProject = false;
					next();
				}
			}
			else {
				view.clear();
				state.assignmentModeActive = false;
				state.assignmentViewModeActive = false;
				state.isFirebaseProject = false;
				next();
			}
		});

		return { view, platforms, state, EditorView, code, EditorButtons, authentication };
	}
});
