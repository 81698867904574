import { App } from "vue";

import { SXCircle, SCheckCircle, SBadgeCheck } from "@havekes/vue3-heroicons";
import { HomeIcon, FolderIcon, FilmIcon, BookOpenIcon, UserGroupIcon, CogIcon, PlusIcon, CodeIcon, TemplateIcon, PuzzleIcon, FlagIcon, SearchIcon, StopIcon, SaveIcon, LightningBoltIcon, LoginIcon, DocumentAddIcon, ExternalLinkIcon, DotsVerticalIcon, FolderOpenIcon, ShareIcon, TrashIcon, UserAddIcon, ArrowLeftIcon, XIcon, CheckIcon, ChevronRightIcon, LockClosedIcon, DuplicateIcon, StarIcon, ChatAlt2Icon } from "@heroicons/vue/outline";

export default {
	install(app: App): void {
		app.component("HomeIcon", HomeIcon);
		app.component("FolderIcon", FolderIcon);
		app.component("FilmIcon", FilmIcon);
		app.component("BookOpenIcon", BookOpenIcon);
		app.component("UserGroupIcon", UserGroupIcon);
		app.component("CogIcon", CogIcon);
		app.component("PlusIcon", PlusIcon);
		app.component("CodeIcon", CodeIcon);
		app.component("TemplateIcon", TemplateIcon);
		app.component("PuzzleIcon", PuzzleIcon);
		app.component("FlagIcon", FlagIcon);
		app.component("SearchIcon", SearchIcon);
		app.component("StopIcon", StopIcon);
		app.component("SaveIcon", SaveIcon);
		app.component("LightningBoltIcon", LightningBoltIcon);
		app.component("LoginIcon", LoginIcon);
		app.component("DocumentAddIcon", DocumentAddIcon);
		app.component("ExternalLinkIcon", ExternalLinkIcon);
		app.component("DotsVerticalIcon", DotsVerticalIcon);
		app.component("FolderOpenIcon", FolderOpenIcon);
		app.component("ShareIcon", ShareIcon);
		app.component("TrashIcon", TrashIcon);
		app.component("UserAddIcon", UserAddIcon);
		app.component("ArrowLeftIcon", ArrowLeftIcon);
		app.component("XIcon", XIcon);
		app.component("CheckIcon", CheckIcon);
		app.component("SXCircle", SXCircle);
		app.component("SCheckCircle", SCheckCircle);
		app.component("ChevronRightIcon", ChevronRightIcon);
		app.component("LockClosedIcon", LockClosedIcon);
		app.component("DuplicateIcon", DuplicateIcon);
		app.component("StarIcon", StarIcon);
		app.component("SBadgeCheck", SBadgeCheck);
		app.component("ChatAlt2Icon", ChatAlt2Icon);
	}
};

