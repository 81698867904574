<template>
	<div class="flex flex-col w-full h-full">
		<EbPageHeader
			:title="view.state.title"
			back-arrow
			@arrowClick="this.$router.push({path: '/showcase'})"
		/>
		<div
			class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8"
		>
			<EbThumbnailCard
				v-for="project in view.state.projects"
				:key="project"
				:title="project.title"
				:subtitle="`${project.platform}`"
				:image="project.image"
				@click="this.$router.push({path: `/showcase/${project.id}`})"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { ShowcaseProjectsModel } from "./showcase-projects-model";
import { View } from "../../constants";

export default defineComponent({
	name: View.ShowcaseProjects,
	setup() {
		const view: ShowcaseProjectsModel = new ShowcaseProjectsModel();

		onMounted(() => {
			view.init();
		});

		return { view };
	}
});
</script>