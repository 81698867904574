<template>
	<div class="flex items-center justify-center w-screen h-screen">
		<div id="loading" />
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	setup() {
		location.href = "https://learn.edublocks.org";
	},
});
</script>
