
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { state } from "@/data/providers/global-provider";
import flagsmith from "flagsmith";
import { getTranslatedText } from "@/data/providers/localization-provider";

export default defineComponent({
	name: ComponentName.EbHeader,
	setup() {
		function isMacOS(): boolean {
			return navigator.userAgent.indexOf("Mac OS X") !== -1;
		}

		return { isMacOS, state, flagsmith, getTranslatedText };
	}
});
