import { reactive } from "@vue/reactivity";
import { newServerConnection, ServerConnection } from "../server/server";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { code } from "@/views/editor/editor-state";

class ServerState {
	public output: string = "";
	public connection: ServerConnection | undefined;
	public terminal: Terminal | undefined;
}

export const serverState: ServerState = reactive(new ServerState);

export async function initServerConnection(): Promise<void> {
	serverState.connection = await newServerConnection();
	const ws: WebSocket = new WebSocket("ws://localhost:8081/terminal");

	ws.onmessage = function(event: MessageEvent): void {
		const message: any = JSON.parse(event.data);
		serverState.output += message.payload;
	};

	serverState.terminal = new Terminal();
	const fitAddon: FitAddon = new FitAddon();
	serverState.terminal.loadAddon(fitAddon);
	serverState.terminal.open(document.getElementById("terminal") as HTMLBodyElement);
	serverState.connection.on("data", (data: string) => {
        serverState.terminal?.write(data); 
	});
	serverState.terminal.onData(serverState.connection.sendData);
}

export function runCodeInTerminal(): void {
    serverState.terminal?.reset();
    serverState.output = "";
    serverState.connection?.runCode(code.value);
}