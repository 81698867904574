import { appInsights } from "@/main";
import { circuitpython } from "@/platforms/circuitpython";
import { html } from "@/platforms/html";
import { microbit } from "@/platforms/microbit";
import { python } from "@/platforms/python";
import { raspberrypi } from "@/platforms/raspberrypi";
import router from "@/router";
import { xmlCode } from "@/views/editor/editor-state";
import { state } from "./global-provider";

export function detectSharedFile(): void {
	const url: string = window.location.href.toString();

	if (url.includes("screenshot")) {
		state.screenshotMode = true;
	}

	if (url.includes("#share") || url.includes("#clone")) {
		if (url.includes("?Python")) {
			state.platform = python;
		}

		if (url.includes("?MicroBit")) {
			state.platform = microbit;
		}

		if (url.includes("?microbit")) {
			state.platform = microbit;
		}

		if (url.includes("?CircuitPython")) {
			state.platform = circuitpython;
		}

		if (url.includes("?HTML")) {
			state.platform = html;
		}

		if (url.includes("?RPi")) {
			state.platform = raspberrypi;
		}

		const fileURL: string = window.location.href.substring(
			window.location.href.lastIndexOf("?") + 1
		);

		const decodedfileURL: string = atob(fileURL);

		fetch(decodedfileURL).then((response: Response) => {
			response.text().then((code: string) => {
				xmlCode.value = code;
				url.includes("#clone") ? null : state.isSharedProject = true;
				url.includes("#clone") ? router.push({path: "/editor"}) : router.push({path: "/sharedproject"});
			});
		});

		appInsights?.trackEvent({
			name: `Load ${state.platform?.name as string} Mode`
		});
	}
}