<template>
	<div
		class="flex items-center justify-between mb-4"
		:class="!noBorder ? 'border-b border-gray-200 pb-6 dark:border-gray-800' : ''"
	>
		<div class="flex items-center space-x-4">
			<ArrowLeftIcon
				v-if="backArrow"
				class="w-6 h-6 transition-all cursor-pointer hover:text-blue-500 dark:text-white"
				@click="this.$emit('arrowClick')"
			/>
			<h1 class="w-full text-xl font-medium text-gray-900 dark:text-white">
				{{ title }} 
			</h1>
			<span
				v-if="beta"
				class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-50 text-blue-500"
			>
				BETA
			</span>
		</div>
		<slot />
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbPageHeader,
	props: {
		title: {
			type: String
		},
		noBorder: {
			type: Boolean
		},
		beta: {
			type: Boolean
		},
		backArrow: {
			type: Boolean
		}
	}
});
</script>
